

export default  (date) => {
    let  month = (date.getMonth() + 1 );
    month = month >= 10  ? month : '0' + month;
    return date ? date.getFullYear()
        + '-'
        + month
        + '-'
        + ( date.getDate() >= 10  ? date.getDate() : '0' + date.getDate() ) : null;
};
